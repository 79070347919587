<template>
  <v-container fluid id="container">
    <v-row class="d-flex align-center mt-8 mt-md-16">
      <v-col offset="1" offset-md="2" cols="10" md="8">
        <v-row class="text-h4 font-weight-bold mb-8 mt-8 mt-md-16" justify="center">
          {{ $t("twoFactorHeader") }}
        </v-row>
        <v-row
          class="text-subtitle-1 text-md-h6 font-weight-regular mt-8"
          justify="center"
        >
          <v-col cols="12" md="6">
            <v-btn block class="text-capitalize">
              {{ $t("skip") }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn
              block
              color="primary"
              class="text-capitalize"
              @click.stop="drawer = !drawer"
            >
              {{ $t("twoFactorStepSetupNow") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          class="text-subtitle-1 d-flex align-center justify-center mt-8 mt-md-12"
        >
          <v-icon color="red-300" class="mr-2">mdi-alert-circle-outline</v-icon>
          <span class="red-300--text">{{ $t("twoFactorStepWarning") }}</span>
        </v-row>
      </v-col>
    </v-row>
    <v-navigation-drawer
      v-model="drawer"
      temporary
      right
      app
      width="485"
      class="position-relative pa-4"
    >
      <div class="d-flex flex-column justify-space-between w-full">
        <div class="w-full">
          <v-col
            class="text-h5 font-weight-bold d-flex align-center justify-space-between"
          >
            {{ $t("twoFactorDrawerHeader") }}
            <v-btn icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-col>
                <v-row class="text-subtitle-1 font-weight-bold mb-0">
                  <v-col cols="12" class="text-left pb-0">
                    {{ $t("twoFactorDrawerStep1SubHeader") }}
                  </v-col>
                </v-row>
                <v-row class="text-subtitle-1 font-weight-normal mt-0">
                  <v-col cols="12" class="text-left pt-0">
                    {{ $t("twoFactorDrawerStep1SubContent") }}
                  </v-col>
                </v-row>
              </v-col>
              <v-radio-group v-model="radioGroup">
                <div>
                  <div class="d-flex align-start justify-start mb-4">
                    <v-radio value="1" class="ml-2 ml-md-2 mr-4 mr-md-2" />
                    <div>
                      <div class="text-subtitle-1 font-weight-bold pb-0">
                        {{ $t("twoFactorDrawerStep1Radio1Label") }}
                      </div>
                      <div class="text-subtitle-1 font-weight-regular pt-0">
                        {{ $t("twoFactorDrawerStep1Radio1Description") }}
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-start justify-start">
                    <v-radio value="2" class="ml-2 ml-md-2 mr-4 mr-md-2" />
                    <div>
                      <div class="text-subtitle-1 font-weight-bold pb-0">
                        {{ $t("twoFactorDrawerStep1Radio1Labe2") }}
                      </div>
                      <div class="text-subtitle-1 font-weight-regular pt-0">
                        {{ $t("twoFactorDrawerStep1Radio2Description") }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-radio-group>
            </v-window-item>
            <v-window-item :value="2">
              <v-col>
                <v-row class="text-subtitle-1 font-weight-bold mb-0">
                  <v-col cols="12" class="text-left pb-0">
                    {{ $t("step") }} {{ " 1/3" }}
                  </v-col>
                </v-row>
                <v-row class="text-subtitle-1 font-weight-bold mb-0">
                  <v-col cols="12" class="text-left pb-0">
                    {{ $t("twoFactorDrawerStep2SubHeader") }}
                  </v-col>
                </v-row>
                <v-row class="text-subtitle-1 font-weight-normal mt-0">
                  <v-col cols="12" class="text-left pt-0">
                    {{ $t("twoFactorDrawerStep2SubContent") }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="text-left">
                <v-label class="text-left text-subtitle-1 font-weight-bold mb-3">{{
                  $t("twoFactorDrawerStep2PhoneLabel")
                }}</v-label>
                <v-text-field filled placeholder="+1 234 567 890" />
              </v-col>
            </v-window-item>
            <v-window-item :value="3">
              <v-col>
                <v-row class="text-subtitle-1 font-weight-bold mb-0">
                  <v-col cols="12" class="text-left pb-0">
                    {{ $t("step") }} {{ " 2/3" }}
                  </v-col>
                </v-row>
                <v-row class="text-subtitle-1 font-weight-bold mb-0">
                  <v-col cols="12" class="text-left pb-0">
                    {{ $t("twoFactorDrawerStep3SubHeader") }}
                  </v-col>
                </v-row>
                <v-row class="text-subtitle-1 font-weight-normal mt-0">
                  <v-col cols="12" class="text-left pt-0">
                    {{
                      `${$t("twoFactorDrawerStep3SubContentPre")} ${$t(
                        "twoFactorDrawerStep3SubContentSup"
                      )}`
                    }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="text-left">
                <v-label class="text-left text-subtitle-1 font-weight-bold mb-3">{{
                  $t("twoFactorDrawerStep2PhoneLabel")
                }}</v-label>
                <v-otp-input variant="solo-filled"></v-otp-input>
              </v-col>
            </v-window-item>
            <v-window-item :value="4">
              <v-col>
                <v-row class="text-subtitle-1 font-weight-bold mb-0">
                  <v-col cols="12" class="text-left pb-0">
                    {{ $t("step") }} {{ " 3/3" }}
                  </v-col>
                </v-row>
                <v-row class="text-subtitle-1 font-weight-bold mb-0">
                  <v-col cols="12" class="text-left pb-0">
                    {{ $t("twoFactorDrawerStep4SubHeader") }}
                  </v-col>
                </v-row>
                <v-row class="text-subtitle-1 font-weight-normal mt-0">
                  <v-col cols="12" class="text-left pt-0">
                    {{ $t("twoFactorDrawerStep4SubContent") }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="text-left">
                <v-label class="text-left text-subtitle-1 font-weight-bold mb-3">{{
                  $t("twoFactorDrawerStep4SubHeader")
                }}</v-label>
                <v-text-field filled placeholder="1234567890" />
              </v-col>
            </v-window-item>
          </v-window>
        </div>

        <div class="text-subtitle-1 text-md-h6 font-weight-regular mt-8 w-full">
          <v-row>
            <v-col cols="12" md="6">
              <v-btn
                class="w-full text-capitalize"
                :disabled="step === 1"
                text
                @click="step--"
              >
                {{ $t("projects.create_project.close_dialog.cancel_button") }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn
                color="primary"
                class="w-full text-capitalize"
                :disabled="step === 4"
                depressed
                @click="step++"
              >
                {{ $t("projects.create_project.close_dialog.continue_button") }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
import makeAuthService from "@/services/api/auth";
import { createNamespacedHelpers } from "vuex";
import { handleDuplicateMixin } from "@/mixins/handleDuplicate.js";
const { mapMutations, mapActions } = createNamespacedHelpers("user");

export default {
  data() {
    return {
      drawer: true,
      radioGroup: 1,
      step: 2,
    };
  },
};
</script>

<style>
.v-navigation-drawer__content {
  display: flex;
  align-items: stretch;
  overflow: hidden !important;
}
</style>
